import React from 'react';
import PropTypes from 'prop-types';
import cn from './EmptyState.module.scss';

export const EmptyState = (props) => {
  const style = {height: props.customHeight};
  const className = `${cn.emptyState} ${props.className}`;

  return (
    <div className={className} style={style}>
      <h5>{props.emptyTitle}</h5>
      <span>{props.emptyMessage}</span>
      {props.reload ? (
        <div className={cn.reloadBtn}>
          <button
            onClick={() => {
              props.reloadAction();
            }}>
            Reload
          </button>
        </div>
      ) : null}
    </div>
  );
};

EmptyState.propTypes = {
  /**
   * custom height
   */
  customHeight: PropTypes.string,
  /**
   * class name for the empty state
   */
  className: PropTypes.string,
  emptyTitle: PropTypes.string,
  emptyMessage: PropTypes.string,
  reloadAction: PropTypes.func,
  reload: PropTypes.bool
};

EmptyState.defaultProps = {
  className: ''
};
