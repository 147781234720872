import axios from 'axios';

import {mapHeader} from '../Helper';

import {BASE_URL, EMPTY_MESSAGE} from '../../Utils/constants';

export const getResources = async () => {
  try {
    const config = {
      method: 'get',
      url: `${BASE_URL}/pwa-resource`,
      headers: mapHeader()
    };
    const response = await axios(config);
    if (response?.data?.data) {
      return response.data.data;
    } else {
      return [];
    }
  } catch (error) {
    throw error;
  }
};
