import React, { Suspense } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import Loader from './Components/Loader';
import { EmptyState } from './Components/EmptyState/EmptyState';

import { useAppReducer } from './App.reducer';
import { ROUTES } from './Routes.constants';

import WelcomeButton from './Components/WelcomeButton';
import { EMPTY_MESSAGE, EMPTY_TITLE } from './Utils/constants';

const Home = React.lazy(() => import('./Routes/Home'));
const Details = React.lazy(() => import('./Routes/Details'));

function App() {
  const [state, resource] = useAppReducer();

  React.useEffect(() => {
    resource.fetch();
  }, []);
  // debugger;
  return (
    <BrowserRouter basename='/app'>
      {state.loading ? (
        <Loader
          hasWrapper
          classList={['theme md']}
          customHeight={'100vh'}
          type='materialCircular'
        />
      ) : state.error ? (
        <Home>
          <EmptyState
            className=''
            emptyTitle={EMPTY_TITLE}
            emptyMessage={
              state?.errorMessage ||
              'There seems to be some issue. Please try again'
            }
            reload={true}
            reloadAction={resource.fetch}
          />
        </Home>
      ) : !state.labels?.length ? (
        <Home>
          <EmptyState
            className=''
            emptyTitle={EMPTY_TITLE}
            emptyMessage={EMPTY_MESSAGE}
          />
        </Home>
      ) : (
        <React.Suspense
          fallback={
            <Loader
              hasWrapper
              classList={['theme md']}
              customHeight={'100vh'}
              type='materialCircular'
            />
          }>
          <Routes>
            <Route
              path={ROUTES.DETAILS}
              element={
                <Details
                  {...state.contents[state.selected]}
                  refetch={resource.fetch}
                />
              }
            />
            <Route
              path={ROUTES.ROOT}
              element={
                <Home>
                  <WelcomeButton
                    labels={state.labels}
                    selectResource={resource.select}
                  />
                </Home>
              }
            />
          </Routes>
        </React.Suspense>
      )}
    </BrowserRouter>
  );
}

export default App;
