import React from 'react';
import {getResources} from './API/Resources';

const INITIAL_STATE = {
  loading: true,
  error: false,
  labels: [],
  contents: [],
  selected: null,
  errorMessage: ''
};

const ACTION = {
  FETCH_DATA: 'appdata/fetch',
  SET_DATA: 'appdata/set',
  SET_ERROR: 'appdata/error',
  SET_SELECTION: 'resource/select'
};

function AppReducer(state, action) {
  console.log(action.type);
  switch (action.type) {
    case ACTION.FETCH_DATA: {
      return {
        ...state,
        loading: true
      };
    }
    case ACTION.SET_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
        labels: [],
        contents: [],
        errorMessage: action.payload.message
      };
    }
    case ACTION.SET_DATA: {
      return {
        ...state,
        loading: false,
        error: false,
        labels: action.payload.map((r, id) => ({id, label: r.type})),
        contents: action.payload,
        selected: null,
        errorMessage: ''
      };
    }
    case ACTION.SET_SELECTION: {
      return {
        ...state,
        selected: action.payload
      };
    }
    default:
      return state;
  }
}

export function useAppReducer() {
  const [state, dispatch] = React.useReducer(AppReducer, INITIAL_STATE);

  const resource = {
    fetch: async () => {
      dispatch({type: ACTION.FETCH_DATA});
      try {
        const response = await getResources();
        dispatch({type: ACTION.SET_DATA, payload: response});
      } catch (error) {
        console.log('Failed to fetch resources', error.message);
        dispatch({type: ACTION.SET_ERROR, payload: error});
      }
    },
    select: (label) => {
      dispatch({type: ACTION.SET_SELECTION, payload: label});
    }
  };

  return [state, resource];
}
