import React from "react";
export const IconWindScreen = (props) => {
  return (
    <svg
      width={91}
      height={50}
      viewBox="0 0 91 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-icon-type="SVG-wind-screen"
      {...props}
    >
      <path
        d="M78.0461 50C77.6862 50 77.3197 49.9477 76.9664 49.8364C68.211 47.1143 44.5753 41.598 14.2717 49.1363C12.374 49.6074 10.4044 48.4557 9.894 46.5581L0.124336 10.1034C-0.386069 8.2123 0.687089 6.27538 2.55857 5.693C11.8244 2.82688 45.354 -5.62097 88.0055 5.81732C89.9032 6.32773 91.0679 8.29082 90.6033 10.1885L81.5535 47.2255C81.3179 48.194 80.6832 49.0316 79.8129 49.5289C79.2632 49.843 78.6546 50 78.0395 50H78.0461ZM44.9876 44.0191C60.3259 44.0191 71.7969 46.8591 77.3524 48.5866C77.9675 48.7764 78.6088 48.7109 79.1716 48.3903C79.7343 48.0696 80.1335 47.5462 80.284 46.918L89.3339 9.88093C89.6349 8.66381 88.8823 7.40743 87.6652 7.08025C45.354 -4.25989 12.1254 4.10943 2.95119 6.94938C1.7537 7.32236 1.06662 8.55911 1.3938 9.76969L11.1635 46.2178C11.4906 47.4284 12.747 48.1678 13.9576 47.8668C25.3763 45.0269 35.8723 44.0191 44.9941 44.0191H44.9876ZM70.9397 40.3874C70.6583 40.3874 70.3638 40.3547 70.0759 40.2827C63.2313 38.5683 44.7455 35.0805 21.0444 39.8443C19.487 40.1584 17.9231 39.3797 17.4912 38.0775L9.84819 15.0504C9.65188 14.4615 9.71078 13.8333 10.0183 13.2836C10.3782 12.6293 11.0588 12.1319 11.8767 11.9291C19.1205 10.123 45.3606 4.78343 78.7332 12.0076C79.5773 12.1908 80.284 12.6751 80.6701 13.3294C80.9907 13.8791 81.0692 14.5073 80.8926 15.0962L73.8123 38.4832C73.4655 39.6349 72.268 40.3874 70.9462 40.3874H70.9397ZM45.0661 36.1275C57.0671 36.1275 66.045 37.9204 70.39 39.0132C71.3257 39.2488 72.3335 38.8235 72.5494 38.1102L79.6296 14.7232C79.7212 14.4157 79.6296 14.1605 79.5315 13.9969C79.3286 13.6501 78.9295 13.3949 78.4452 13.2902C45.3475 6.12488 19.3561 11.4121 12.1777 13.2051C11.7131 13.3229 11.3336 13.5846 11.1504 13.9184C11.0588 14.0819 10.9737 14.3372 11.0784 14.6447L18.7214 37.6652C18.9439 38.3327 19.8861 38.7449 20.7761 38.5617C29.7147 36.7688 37.9204 36.134 45.0596 36.134L45.0661 36.1275ZM24.8528 29.4922L21.4174 15.3645L20.1479 15.6721L23.5833 29.7998L24.8528 29.4922ZM31.2263 32.378L26.6457 14.0558L25.3763 14.3699L29.9568 32.6921L31.2263 32.378Z"
        fill="url(#paint0_linear_5271_2926)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5271_2926"
          x1={45.3514}
          y1={-0.00512695}
          x2={45.3514}
          y2={50}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00AAA8" />
          <stop offset={1} stopColor="#192C50" />
        </linearGradient>
      </defs>
    </svg>
  );
};

IconWindScreen.iconType = "SVG-wind-screen";
