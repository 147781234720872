import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const Loader = (props) => {
  let customStyle = {};
  if (props.customHeight) {
    customStyle = {
      minHeight: props.customHeight
    };
  }
  const withWrapper = () => {
    const overlayClassName = props.hasOverlay
      ? 'loader-block overlay'
      : 'loader-block';
    const className = `${overlayClassName} ${props.className}`;
    return (
      <div className={className} style={customStyle}>
        {withoutWrapper()}
      </div>
    );
  };

  const withoutWrapper = () => {
    let classList = [`${props.type}Loader`];
    if (props && props.classList && typeof props.classList === 'object')
      classList = classList.concat(props.classList);
    let list = [];
    switch (props.type) {
      case TYPES.CIRCULAR:
        for (let i = 0; i < 13; i++) {
          list.push(<div key={i} className='circularLoader__item' />);
        }
        break;
      case TYPES.LINEAR:
        return <div className={classList.join(' ')}></div>;
      case TYPES.MATERIAL_CIRCULAR:
        return (
          <div className={classList.join(' ')}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='45'
              height='45'
              viewBox='0 0 45 45'>
              <g transform='translate(0,-252)'>
                <circle cx='22.584078' cy='274.60489' r='18.470901' />
              </g>
            </svg>
          </div>
        );
    }
    return <div className={classList.join(' ')}>{list}</div>;
  };

  if (props && props.hasWrapper) return withWrapper();
  else return withoutWrapper();
};

export const TYPES = {
  CIRCULAR: 'circular',
  LINEAR: 'linear',
  MATERIAL_CIRCULAR: 'materialCircular'
};

Loader.propTypes = {
  /**
   * custom height
   */
  customHeight: PropTypes.string,
  /**
   * has Overlay
   */
  hasOverlay: PropTypes.bool,
  /**
   * class list
   */
  classList: PropTypes.any,
  /**
   * has wrapper
   */
  hasWrapper: PropTypes.bool,
  /**
   * main block class name
   */
  className: PropTypes.string,
  /**
   * loader type
   */
  type: PropTypes.oneOf(['circular', 'linear', 'materialCircular'])
};

Loader.defaultProps = {
  type: TYPES.LINEAR,
  className: ''
};

export default Loader;
