import React from "react";

export const IconArrow = (props) => {
  return (
    <svg
      viewBox="0 0 7 12"
      xmlns="http://www.w3.org/2000/svg"
      data-icon-type="SVG-arrow"
      {...props}
    >
      <path
        d="M0.161862 11.1221C-0.053954 11.3229 -0.053954 11.6485 0.161862 11.8494C0.377678 12.0502 0.727587 12.0502 0.943401 11.8494L0.161862 11.1221ZM6.44737 6.00001L6.83814 6.36366C6.94177 6.26722 7 6.1364 7 6.00001C7 5.86361 6.94177 5.7328 6.83814 5.63635L6.44737 6.00001ZM0.943401 0.150631C0.727587 -0.0502103 0.377678 -0.0502103 0.161862 0.150631C-0.053954 0.351472 -0.053954 0.677102 0.161862 0.877942L0.943401 0.150631ZM0.943401 11.8494L6.83814 6.36366L6.0566 5.63635L0.161862 11.1221L0.943401 11.8494ZM6.83814 5.63635L0.943401 0.150631L0.161862 0.877942L6.0566 6.36366L6.83814 5.63635Z"
        fill="#002856"
      />
    </svg>
  );
};

IconArrow.iconType = "SVG-arrow";
