import React from "react";

export const IconDownload = (props) => {
  return (
    <svg
      width={17}
      height={16}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-icon-type="SVG-download"
      {...props}
    >
      <g clipPath="url(#clip0_5271_3072)">
        <path
          d="M13.1574 6.66786C12.9774 6.48297 12.8563 6.58498 12.6763 6.77306L8.80887 10.7452V-1.54732C8.80887 -1.79598 8.6692 -2 8.49848 -2C8.32777 -2 8.1881 -1.79916 8.1881 -1.54732V10.7452L4.32065 6.77306C4.14062 6.58817 4.02888 6.48297 3.84576 6.66786C3.66263 6.85276 3.4826 6.94202 3.66263 7.12692L8.16947 11.7557C8.25638 11.845 8.37433 11.896 8.49848 11.896C8.62264 11.896 8.74059 11.845 8.8275 11.7557L13.3343 7.12692C13.5144 6.94202 13.3406 6.85276 13.1605 6.66786H13.1574Z"
          fill="white"
          stroke="white"
        />
        <path
          d="M16.0595 16H0.940478C0.422129 16 0 15.5665 0 15.0341V12.385C0 12.2096 0.139675 12.0662 0.310389 12.0662C0.481103 12.0662 0.620778 12.2096 0.620778 12.385V15.0341C0.620778 15.2158 0.763557 15.3624 0.940478 15.3624H16.0595C16.2364 15.3624 16.3792 15.2158 16.3792 15.0341V12.4455C16.3792 12.2702 16.5189 12.1267 16.6896 12.1267C16.8603 12.1267 17 12.2702 17 12.4455V15.0341C17 15.5665 16.5779 16 16.0595 16Z"
          fill="white"
          stroke="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_5271_3072">
          <rect width={17} height={16} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

IconDownload.iconType = "SVG-download";
