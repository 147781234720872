export const BASE_URL = '/api/v1';
export const RESOURCE_TYPE = {
  COMPANY: 'company',
  PERSONAL: 'personal'
};

export const EMPTY_MESSAGE = 'Please contact admin to publish content';
export const EMPTY_TITLE = 'No content available';
export const PRIVACY_POLICY =
  'https://acquia-uat-fpau.eclipx.xyz/privacy-policy';
export const TERMS_OF_USE = 'https://acquia-uat-fpau.eclipx.xyz/terms-of-use';
export const PHONE_NUMBER = '1300666001';
export const COPYRIGHT =
  '© FleetPartners Group Limited 2023 ABN 85 131 557 901';
export const DEFAULT_TITLE = 'FleetPartners';
export const DEFAULT_DESCRIPTION = 'PWA for FleetPartners'
