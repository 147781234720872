import React from 'react';
import {useNavigate} from 'react-router-dom';

import SVGIcons from '../SVGIcons';

import cn from './WelcomeButton.module.scss';

import {ROUTES} from '../../Routes.constants';

export function WelcomeButton(props) {
  const navigate = useNavigate();

  const onClickWelcome = (id) => () => {
    props.selectResource(id);
    navigate(ROUTES.DETAILS);
  };

  return props.labels.map((l) => (
    <div key={l.id} onClick={onClickWelcome(l.id)} className={cn.welcomeButton}>
      <span>{l.label}</span>
      <span>
        <SVGIcons type='SVG-arrow' />
      </span>
    </div>
  ));
}

WelcomeButton.defaultProps = {
  labels: []
};
